@import "styles/variables.scss";

.customSnackbar {
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .button {
    color: #c3b3e2;
    font-weight: 600;
    transition: all 0.3s;

    &:hover {
      color: $color_primary;
    }
  }
}

div[class~="SnackbarItem-message"] {
  font-weight: 400;
  font-size: 16px;
}

div[class~="SnackbarItem-variantInfo"] {
  background-color: $color_primary !important;
}
