$color_primary: #8868c7;
$color_error: #d33131;
$color_grey: #d6d6d6;
$color_white: #ffffff;
$color_dark_grey: rgba(51, 51, 51, 0.5);
$color_text: #333333;
$color_background_secondary: rgba(136, 104, 199, 0.1);
$color_background_tertiary: rgba(136, 104, 199, 0.2);

$breakpoint-large: 1150px;
$breakpoint-small: 720px;

$topbar-height: 120px;
$topbar-height-sm: 90px;

$global-padding: 24px;
