html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "museo-sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: "museo-sans", sans-serif;
}

#root {
  height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 32px;
  margin: 0;
}

a {
  color: inherit;
  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}
